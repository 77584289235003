import React from 'react'
import {
    Button,
    Container,
    Grid,
    Header,
    List,
    ListItem,
    Segment,
} from 'semantic-ui-react'
import '../App.css';



function Partners() {
    const partners = [
        "Mikell and Will Abney",
        "Katie and Phil Armentor",
        "George Baldwin",
        "Mike and Julie Blumenthal",
        "Casey and Chuck Boettcher",
        "Lynn and Susan Bourdon",
        "Emily and Trey Burke",
        "Elizabeth Chapman",
        "Mark Dahl",
        "Katie and John Daily",
        "Meredith and Greg Duncan",
        "Becca and James Edmiston",
        "Brett and Tobi Fuller",
        "Kelly and Greg Funderburk",
        "Whitney and Chris Gowan",
        "Meg and Jeff Hamilton",
        "Cindy and Tom Heckard",
        "Tom and Margaret Hoban",
        "Loma and Bill Hobson",
        "Jeff Kilburg",
        "Mary Ellen and Karl Klanke",
        "Connie and Allan Klenke",
        "Erin and Greg Klenke",
        "Mary and Ken Klenke",
        "Michele, Kevin and Joe Klenke, Loblolly Cellars",
        "Lisa and Scott Kovalik",
        "Lindsay and Paul Laudadio",
        "Paddy Magliolo, Willie Joe's Processing",
        "Brett McCarthy, McCarthy Ranch Services",
        "Leah and Brian McDonald",
        "Terry and Laurie McNamara",
        "Kathleen O'Connor",
        "Megan and Nick Pepitone",
        "Stephen Portnoy",
        "Cole Rongey",
        "David and Ann Rood",
        "Leslie and Steve Shofner",
        "Susan and Tim Thomas",
        "Mike and Joanne VanKeirsbilck"
    ]

    const listItems = partners.map((name) => <ListItem content={name}/>)

    return (
        <Container text>
            <Segment vertical>
            <Header as={"h2"} content="Partners of the Herd" />
            </Segment>
            <Segment raised>
                <Header as='h4' content="Many thanks to these folks who have helped me get started with their donations and support:" />
                <List relaxed>
                    { listItems }
                </List>
            </Segment>
        </Container>
    )
};

export default Partners;
