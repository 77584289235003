import React from 'react'
import { 
    Container,
    Header,  
    List, 
    ListItem, 
    Image
} from 'semantic-ui-react'
import logo from '../logo1.png'
import '../App.css';

function About() {
    return (
        <Container text style={{ padding: 20 }}>
            <Image src={logo} fluid />
            <Header as='h1' color='blue'>About Community Cattle</Header>
            <Header as='h3' color='blue'>Mission</Header>
            <p>
                Our mission is to feed our neighbors in need by raising beef cattle and donating good quality meat to local food pantries and other charitable organizations.
            </p>
            <Header as='h3' color='blue'>Areas of focus:</Header>
            <p>
                <List bulleted>
                <ListItem>
                    To be a good steward of our herd, providing good nutrition and a healthy environment for our seven Brangus cows, their calves and our Simbrah bull.  We will raise these calves to their optimal potential!
                </ListItem>
                <ListItem>
                    To partner with local food pantries and non-profit organizations we hope to serve, keeping their freezers stocked.
                </ListItem>
                <ListItem>
                    To connect with people and businesses who support our mission and keep donors informed of our progress.
                </ListItem>
                <ListItem>
                    To embrace our core values of integrity, compassion, respect, service, and collaboration in all we do.
                </ListItem>
                </List>
            </p>
            <Header as='h3' color='blue'>My Background</Header>
            <p>
            My family and my Catholic schools have instilled in me a desire to serve others and to help those in
need. With the support of our donors, I aim to help families and other non-profits serve some quality
protein at the dinner table.
            </p>
            <p>
            I became interested in raising cattle when my family bought some land in New Ulm, Texas that has big,
open pastures where the previous owner kept a herd. My parents and six siblings have been a huge
support in getting this business going. I am very grateful for them and for all the skills and knowledge
they share with me.
            </p>
            <p>
            As a student at St. Thomas High School in Houston and the last Klenke kid at home, we are able to get to
the ranch most weekends to tend to the cattle. I'm learning about growing good hay, running electric
fences and moving the herd between pastures.
            </p>
            <p>
                <b>
                Raising cattle to feed the community.
                </b>
                &nbsp;Meat first!
            </p>
            <p>
            Sincerely,<br/>
            Quinn Klenke<br/>
            Founder, Community Cattle
            </p>
        </Container>
    )
}

export default About;