import { Image, Menu, Grid, Header } from "semantic-ui-react";
import React from 'react';
import logo from '../logo1.png'

export default function CommunityCattleHeader() {
    return (
        <Menu pointing secondary stackable>
            <Menu.Menu position="left">
                <Menu.Item
                    as='a'
                    active={window.location.pathname === "/about"}
                    onClick={() => document.location = "/about"}
                    name="About"
                />
                <Menu.Item
                    as='a'
                    active={window.location.pathname === "/community"}
                    onClick={() => document.location = "/community"}
                    name="Communities we serve"
                />
                <Menu.Item
                    as='a'
                    active={window.location.pathname === "/getInvolved"}
                    onClick={() => document.location = "/getInvolved"}
                    name="Get Involved"
                />
                <Menu.Item
                    as='a'
                    active={window.location.pathname === "/partners"}
                    onClick={() => document.location = "/partners"}
                    name="Partners of the Herd"
                />
                <Menu.Item
                    as='a'
                    active={window.location.pathname === "/gallery"}
                    onClick={() => document.location = "/gallery"}
                    name="Gallery"
                />
                <Menu.Item
                    as='a'
                    active={window.location.pathname === "/newsletters"}
                    onClick={() => document.location = "/newsletters"}
                    name="Newsletters"
                />
            </Menu.Menu>
        </Menu>

    )
}