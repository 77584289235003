import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css';
import CommunityCattleHeader from './components/CommunityCattleHeader'
import About from './components/About'
import CowGallery from './components/Gallery';
import GetInvolved from './components/GetInvolved';
import CommunityWeServe from './components/CommunityWeServe';
import Partners from './components/Partners';
import Newsletters from './components/Newsletters'

function App() {
  return (
    <div>
      <CommunityCattleHeader />
      <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<About />}/>
            <Route path="/about" element={<About />}/>
            <Route path='/gallery' element={<CowGallery />}/>
            <Route path='/getInvolved' element={<GetInvolved />}/>
            <Route path='/community' element={<CommunityWeServe />}/>
            <Route path='/partners' element={<Partners />}/>
            <Route path='/newsletters' element={<Newsletters />}/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;